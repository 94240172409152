export default {
  text: '#111111',
  text2: '#111111',
  primary: '#007E01',
  secondary: '#007E01',
  background: 'white',
  backgroundPrimary: '#f5f5f5',
  backgroundSecondary: '#f5f5f5',
  backgroundTertiary: '#f5f5f5',
  light: '#FFF',
  dark: '#000000'
}
