export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.logoLocationContainer': {
      // display: 'none',
      padding: '0rem',
      position: 'static',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    '.logo': {
      // display: 'none',
      a: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
      img: {
        maxHeight: ['70px', '90px', '90px', ''],
        filter: 'unset'
        // maxWidth: ['', '', 'unset', 'unset'],
      }
    },
    '.logoScrolled': {
      // display: 'none',
      img: {
        maxHeight: ['40px', '60px', '60px', ''],
        filter: 'unset'
      }
    },

    '.container': {
      padding: ['0.5rem 1rem', '', '0.5rem 2rem'],
      backgroundColor: 'transparent',
      top: '0rem',
      position: 'relative',
      overflow: 'visable',
      display: 'flex',
      flexDirection: ['row', '', 'column'],
      justifyContent: ['space-between', '', 'center'],
      alignItems: 'center',
      height: ['15vh', '', '25vh'],
      maxHeight: '25vh',
      width: '100%',
      '.smallNavMenu': {
        '::before': {
          content: "''",
          width: '10%',
          flexGrow: 1,
          height: '2px',
          borderBottom: 'solid 1px lightgrey',
          mr: '2rem'
        },
        '::after': {
          content: "''",
          flexGrow: 1,
          width: '10%',
          height: '2px',
          borderBottom: 'solid 1px lightgrey',
          ml: '2rem'
        },
        display: ['none', '', 'flex', 'flex'],
        mb: '1rem',
        mx: 'auto',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        '.navItem': {
          color: 'text',
          fontFamily: 'heading',
          a: {
            fontSize: '0.8rem',
            ':hover': {
              textDecoration: 'underline',
              color: 'grey'
            }
          }
        },
        '.react-reveal': {
          opacity: '1'
        }
      }
    },
    '.containerScrolled': {
      border: 'none',
      padding: '0.5rem 1rem',
      backgroundColor: '#2f484d',
      // width: 'fit-content',
      position: 'relative',
      right: '0rem',
      top: '0rem',
      left: 'unset',
      borderBottom: '1px solid',
      borderColor: 'primary',
      overflow: 'visable',
      '.navItem': {
        a: {
          color: 'white'
        }
      }
    },
    '.hamburger': {
      borderRadius: '100px',
      padding: '0.75rem',
      borderColor: 'primary',
      backgroundColor: 'rgba(255,255,255, 0.6)',
      display: ['', '', 'none'],
      '> div': {
        backgroundColor: 'primary'
      }
    },

    '.phoneContainer': {
      display: 'flex',
      border: 'none',
      margin: '0rem 1rem',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      a: {
        backgroundColor: 'primary',
        padding: '0.5rem',
        color: 'light',
        textAlign: 'center',
        margin: '0.5rem 0rem'
      }
    },
    '.hamburgerOpen': {
      borderColor: 'white',
      '> div': {
        backgroundColor: 'light'
      }
    },
    '.navMenuLogo': {
      display: 'none'
    },
    '.navMenuOpen': {
      alignItems: ['', '', '', 'flex-end'],
      padding: ['', '', '', '4rem'],
      width: '80%',
      maxWidth: '500px',
      backgroundColor: 'primary',
      '.navItem': {
        a: {
          color: 'light',
          ':hover': {
            textDecoration: 'underline'
          }
        }
      },
      '.seperatorLine': {
        margin: ['', '', '', '1rem 0 1rem auto']
      },
      '.phoneSocialContainer': {
        flexDirection: ['', '', '', 'column'],
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem'
        }
      },
      '.smallNavHeading ': {
        textAlign: ['', '', '', 'right'],
        color: 'white'
      },

      '.socialIconsContainer': {
        alignItems: ['', '', '', 'flex-end'],
        textAlign: ['', '', '', 'right'],
        '.smallNavHeading ': {
          textAlign: 'right'
        },
        a: {
          textAlign: ['', '', '', 'right'],
          justifyContent: ['', '', '', 'flex-end'],
          fontSize: '1rem',
          svg: {
            path: {
              fill: 'white'
            }
          }
        }
      }
    },

    '.navBlock': {
      right: 'unset',
      top: '0vh',
      left: '-400vw'
    }
  },

  footer: {
    background: 'transparent',
    maxHeight: '15vh',
    height: '15vh',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'wrap',
    color: 'dark',
    padding: ['0.25rem', '0.25rem', '0.25rem', '0.25rem'],
    '.image': {
      display: 'none'
    },
    '.multiButtonContainer': {
      display: 'none'
    },
    '.contactDetails-container': {
      width: 'fit-content',
      display: 'flex',
      flexDirection: 'row',
      '.text': {
        br: { display: 'none' },
        fontSize: '0.9rem',
        margin: '0.35rem'
      }
    },
    '.copyright': {
      width: 'fit-content',
      color: 'dark',
      textAlign: 'center',
      margin: '0rem 0.75rem',
      fontSize: '0.8rem'
    },
    '.socialIconsContainer': {
      width: 'fit-content',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      a: {
        margin: '0rem',
        svg: {
          width: '20px',
          height: '20px',
          padding: '0rem',
          margin: '0rem',
          border: 'solid 1px white',
          borderRadius: '100px',
          path: {
            fill: 'text'
          },
          ':hover': {
            backgroundColor: 'grey'
          }
        },
        margin: '0.3rem'
      }
    },
    '.gonationLogo': {
      width: 'fit-content',
      filter: 'brightness(0)',
      '.poweredByText': {
        fontSize: '0.6rem'
      }
    }
  },

  ctaWidget: {
    display: 'none !important'
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['1.5rem', '1.75rem', '2rem', '2.25rem', '2.5rem'],
    fontWeight: '600',
    letterSpacing: '0px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'inherit',
    marginBottom: '1.5rem',
    textTransform: 'uppercase',
    fontFamily: 'heading'
  },
  subtitle: {
    width: 'fit-content',
    textAlign: 'inherit',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['1.5rem', '1.75rem', '2rem', '2rem'],
    marginBottom: '1.5rem',
    border: 'none',
    color: 'text2',
    fontFamily: 'subheading',
    opacity: '0.8',
    fontWeight: '200'
  },
  text: {
    lineHeight: '1.75',
    color: 'text',
    fontSize: ['1rem', '', '1.1rem'],

    p: {
      color: 'text',
      lineHeight: '1.75',
      textAlign: 'left',
      fontSize: ['1rem', '', '1.1rem']
    }
  },

  sideBySide1: {
    height: ['', '', '85vh', '85vh'],
    backgroundColor: 'background',
    padding: ['1rem', '', '0rem', '0rem'],
    margin: '0rem',
    '.lazyload-wrapper': {
      minHeight: '50vh',
      justifyContent: 'flex-start',
      width: ['', '', '60%', '65%']
    },
    '.content': {
      position: 'relative',
      alignItems: 'flex-start',
      margin: ['1rem 0rem', '', '0rem'],
      width: ['', '', '40%', '35%']
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '5'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1'
  },

  // centerBlock: {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   minHeight: '90vh',
  //   color: 'white',
  //   textAlign: 'center',

  //   padding: ['6rem 1rem', '8rem 1rem'],
  //   '.title': {
  //     variant: 'customVariants.title',
  //   },
  //   '.subtitle': {
  //     variant: 'customVariants.subtitle',
  //   },

  //   '.text': {
  //     variant: 'customVariants.text',
  //   },
  //   a: {
  //     order: '4',
  //   },
  //   '.ctaButton': {
  //     variant: 'buttons.primary',
  //   },
  // },

  // centerBlock2: {
  //   variant: 'customVariants.centerBlock',
  //   background: 'none',
  //   height: 'auto',
  //   color: 'black',
  // },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    // height: 'inherit',
    px: ['1rem', '1.5rem', '2rem'],
    // overflow: 'hidden',
    transform: 'none',
    '.slick-initialized .slick-slide>div': {
      height: '300px'
    },
    height: 'auto',
    '.slick-slider, .slick-list, .slick-track, .slick-slide, .slick-slide > div, .slick-slide img': {
      width: '100%',
      height: 'auto',
      objectFit: 'contain'
      //   height: 'inherit'
    },
    '.slick-arrow': {
      display: 'none !important'
    }
    // backgroundColor: 'backgroundPrimary',
    // height: ['', '', '100vh'],
    // '.content': {
    //   width: ['100%', '', '50%']
    // },
    // '.lazyload-wrapper': {
    //   width: ['100%', '', '50%']
    // },
    // '.title': {
    //   variant: 'customVariants.title',
    //   fontSize: ['2.5rem', '3rem', '4rem', '4.5rem', '4.5rem'],
    //   border: 'none',
    //   margin: '0rem 0rem 1rem',
    //   padding: '0rem',
    //   textAlign: 'center'
    // },
    // '.subtitle': {
    //   variant: 'customVariants.subtitle',
    //   width: '100%',
    //   textAlign: 'center',
    //   justifyContent: 'center'
    // },
    // '.text': {
    //   variant: 'customVariants.text'
    // },
    // a: {
    //   order: '4'
    // },
    // '.ctaButton': {
    //   variant: 'buttons.primary'
    // }
  },

  homepageHeroShout: {
    // display: 'none'
  },

  menuDisclaimer: {
    padding: 0,
    background: 'white',
    backgroundColor: 'white',
    h2: {
      display: 'none'
    }
  },

  homepageAbout: {
    backgroundColor: '#ffffff!important',
    background: 'white!important',
    padding: 0,
    '.lazyload-wrapper': {
      width: ['', '', '30%', '20%'],
      '.image': {
        objectFit: 'contain',
        objectPosition: 'left'
      }
    },
    p: {
      marginBottom: '4px!important'
    },
    '.content': {
      width: ['', '', '70%', '80%'],
      padding: ['1rem', '2rem', '3rem', '4rem', '5rem']
    }
  },

  homepageCoffee: {
    variant: 'customVariants.sideBySide1',
    backgroundColor: 'secondary'
  },
  homepageBoxes: {
    backgroundColor: 'transparent',
    padding: ['2rem', '', '', '2rem'],
    '.box': {
      width: ['100%', '', 'calc(50% - 3rem)', 'calc(33.3% - 3rem)'],
      margin: '1.5rem',
      border: 'solid 1px #efefef',
      transition: 'all ease-in-out 1s',
      borderRadius: '0px',
      ':hover': {
        border: 'solid 1px black',
        '.image': {
          filter: 'grayscale(100%)'
        },
        '.title': {
          borderBottom: 'solid 2px black'
        }
      },
      '.image': {
        height: ['auto', '', '350px'],
        objectFit: 'cover',
        transition: 'all ease-in-out 1s'
      },
      '.title': {
        variant: 'customVariants.title',
        fontSize: ['1.25rem', '1.5rem', '1.75rem', '2rem', '2.25rem'],
        transition: 'all ease-in-out 1s',
        borderBottom: 'solid 2px #efefef',
        paddingBottom: '0.5rem'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
        fontWeight: '300'
      },
      '.text': {
        variant: 'customVariants.text',
        padding: '0rem 1rem'
      }
    }
  },
  homepageLunch: {
    variant: 'customVariants.sideBySide1',

    backgroundColor: 'backgroundPrimary'
  },
  homepageDinner: {
    variant: 'customVariants.sideBySide1',
    backgroundColor: 'secondary'
  },
  homepageTakeout: {
    variant: 'customVariants.sideBySide1',
    backgroundColor: 'backgroundTertiary'
  },

  homepageShout: {
    backgroundColor: 'background',
    marginBottom: '3rem',
    padding: ['1rem', '', '2rem 1rem', '3rem 2rem 10rem'],
    // borderTop: '1px solid',
    // borderBottom: '1px solid',
    flexWrap: ['wrap', 'wrap', 'wrap', 'wrap'],
    '.title': {
      variant: 'customVariants.title',
      borderBottom: '3px solid',
      borderColor: 'text2',
      fontFamily: 'heading',
      width: ['', '', '100%'],
      color: 'primary',
      backgroundColor: 'transparent',
      letterSpacing: '0px',
      textAlign: 'center'
    },
    '.text': {
      fontFamily: 'heading',
      fontSize: ['1.75rem', '', ''],
      order: ['4', '', '3'],
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      fontWeight: 'normal',
      padding: ['', '', '0rem 2rem'],
      backgroundColor: ['backgroundSecondary'],
      color: 'text',
      textTransform: 'none',
      padding: ['2rem', '2rem'],

      //   marginTop: '3rem',
      width: ['', '', '55%']
    },
    '.date': {
      backgroundColor: 'secondary',
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      fontSize: '0.8rem',
      maxWidth: 'unset',
      color: 'white',
      flexGrow: '1',
      fontWeight: 'bold',
      justifyContent: 'center',
      position: 'relative'
    },
    '.imageContainer': {
      position: 'relative',
      border: ['', '', '', 'solid 0.5rem'],
      borderColor: ['', '', '', 'white'],
      order: ['', '', '4'],
      cursor: 'pointer',
      flexGrow: '1'
    },

    margin: '0rem 0rem',
    '.shoutCTABtns ': {
      border: ['', '', '', 'solid 8px'],
      borderColor: ['', '', '', 'background'],
      flexDirection: ['row', 'row', 'row', 'column'],
      width: ['100%', '', '', '100%'],
      marginBottom: ['2rem', '2rem', 0],
      a: {
        width: ['50%', '', '', '100%'],
        writingMode: ['unset', 'unset', 'unset', 'unset'],
        padding: '1rem',
        backgroundColor: 'transparent',
        color: 'primary',
        fontFamily: 'sans-serif',
        fontWeight: 'bold',
        ':hover': {
          backgroundColor: 'primary',
          color: 'white'
        }
      }
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.title': {
      variant: 'customVariants.title',
      borderBottom: '2px solid',
      borderColor: 'primary'
    }
  },

  bio: {
    variant: 'customVariants.sideBySide1',
    marginTop: '0rem',
    backgroundColor: '#e9e9e2',
    padding: '5rem 2rem 0rem',
    'div.content': {
      width: ['', '', '60%']
    },

    'div.lazyload-wrapper': {
      overflow: 'hidden',
      width: ['', '', '40%'],
      img: {
        objectFit: 'contain',
        objectPosition: 'top'
      }
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  menu: {
    py: '0rem',
    backgroundSize: '35%',
    backgroundPosition: '100%',
    '.menuSectionTitle': {
      variant: 'customVariants.title',
      fontSize: ['2.25rem', '2.25rem', '2.5rem', '2.75rem'],
      textAlign: 'left',
      fontFamily: 'body',
      border: 'none',
      mb: '0rem',
      color: 'text',
      py: '0rem'
    },

    '.menuSectionDescription': {
      textAlign: 'left',
      marginBottom: '1.5rem',
      maxWidth: ['100%', '75%'],
      padding: '1rem'
    },

    '.menuItemsContainer': {
      display: 'flex',
      flexDirection: 'column'
    },

    '.menuItemContainerImgActive': {
      height: '100%',
      width: '100%',
      maxWidth: ['100%', '', '80%', '70%'],
      '.menuItemInnerContainer': {
        flexDirection: ['column', '', 'row'],
        justifyContent: 'center',
        alignItems: 'center',
        '.menuItemContentContainer': {
          width: ['100%', '', '', '70%'],
          '.menuItemNamePriceContainer': {
            display: 'inline'
          },
          '.menuItemName': {
            variant: 'customVariants.title',
            fontSize: ['1.25rem', '1.25rem'],
            color: 'text',
            mb: '0rem',
            display: 'inline',
            borderRight: '2px solid',
            pr: '0.75rem',
            mr: '0.75rem'
            // width: ['60%', '75%'],
          },

          '.menuPriceContainer': {
            display: 'inline',
            '.menuItemPrice': {
              display: 'inline'
            }
          },
          '.menuItemDescription': {
            fontFamily: 'heading',
            display: 'inline',
            opacity: '1',
            whiteSpace: 'normal',
            color: 'text',
            fontSize: ['1.1rem', '1.3rem', '1.3rem', '1.3rem', '1.3rem']
          }
        },

        '.menuItemImageContainer': {
          width: ['100%', '', '', '30%'],

          '.menuItemImg': {
            // objectPosition: 'top',
          }
        }
      }
    },
    '.menuItemPageContent': {
      '.itemVariantsContainer': {
        display: 'flex',
        flexWrap: 'wrap'
      },
      '.variantsContainer': {
        display: 'flex',
        flexWrap: 'wrap',
        flexGrow: '1',
        margin: '0.5rem 1rem 0.5rem 0rem'
        // width: ['33.3%'],
      },
      '.variantContainer': {
        display: 'flex',

        fontFamily: 'body2',

        padding: '0.75rem',
        borderRadius: '10px'
      },
      '.menuItemPriceLabel': {
        fontWeight: 'bold',
        fontSize: '0.8rem',
        marginBottom: '0.25rem'
      },
      '.variantContainerFloatTopRight': {
        fontSize: ['1.25rem', '1.5rem', '2rem']
      },
      '.menuItemLabelOptions': {
        fontSize: '1rem',
        maxWidth: '350px'
      },
      '.menuItemPriceVariants': {
        fontSize: '1rem',
        maxWidth: '350px'
      },
      '.menuItemPrice': {
        fontWeight: 'bold',
        fontFamily: 'heading',
        ml: '0.75rem'
      },
      '.menuItemDescription': {
        width: '100%',
        backgroundColor: 'white',
        opacity: '1',
        lineHeight: '1.7',
        padding: '1rem 0.25rem'
      }
    },
    '.menuItemButton': {
      display: 'none'
    },
    '.menuTab': {
      fontFamily: 'body',
      textTransform: 'uppercase',
      background: 'transparent',
      variant: 'customVariants.title',
      fontSize: ['1.25rem', '1.5rem', '1.5rem', '1.5rem', '1.5rem'],
      color: 'text',
      p: '0.5rem 0.75rem'
    },
    '.menuTabActive': {
      backgroundColor: 'primary',
      color: 'white'
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumTitle': {
      padding: '0.5rem'
    },
    '.sauces ': {
      '.albumImage': {
        objectFit: 'contain',
        gridRowEnd: 'span 2'
      }
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset'
    },
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px'
        // filter: 'brightness(0) invert(1)',
      }
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: '25%'
      // height: '100%',
    },
    '.eventDays, .eventDate': {
      color: 'primary'
    },
    '.eventItemDescription': {
      maxHeight: '250px',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    h2: {
      variant: 'customVariants.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem']
    },
    '.text': {
      color: 'white'
    },
    backgroundPosition: 'center center',
    color: 'white',
    backgroundColor: 'secondary',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'white',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'white',
      '::placeholder': {
        color: 'white'
      }
    },

    '.submitBtn': {
      variant: 'buttons.secondary',
      height: 'unset',
      width: 'auto'
    }
  },

  locationMap: {
    order: '3',
    '.content_container': {
      padding: '1.5rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start'
    },
    'h3.title': {
      variant: 'customVariants.title',
      display: 'flex',
      order: 'unset',
      width: '100%',
      padding: '0rem',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      textAlign: 'left',
      fontSize: ['1.25rem', '1.25rem', '1.5rem', '1.5rem', '1.5rem'],
      mb: '0rem'
    },
    '.contactDetails-container': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      textAlign: 'left',
      padding: '1.5rem 0rem',
      mb: '1rem',
      '.text': {
        m: '0rem',
        mb: '0.75rem'
      }
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      '.hoursContainer': {
        padding: ['1rem 0rem', '', '', '']
      }
    },
    '.dayofWeekText': {
      margin: '0rem auto 0rem 0rem'
    },
    '.textContent': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex'
    }
  },

  // ? ========================
  // ? ====  Press page  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.boxesHeading': {
      display: 'none'
    },
    '.textContent': {
      backgroundColor: 'white',
      '.subtitle': {
        color: 'dark'
      }
    },
    '.imageContainer': {
      backgroundColor: 'white',
      img: {
        objectFit: 'contain',
        padding: '1rem'
      }
    }
  },

  pressPage: {
    backgroundColor: 'background'
  }
}
